<!--  -->
<template>
  <div class="info">
    <el-button type="primary" @click="$router.go(-1)" style="position:absolute;top:8%; left:69%">返回</el-button>
    <el-card>
      <div slot="header" class="clearfix">
        <span>分类名称</span>
        <AuthorityComponent
          ComponentName="el-button"
           v-if="$route.meta.authority.subPage.setting.button.create"
          style="float: right; padding: 3px 0"
          type="text"
          @click="add"
        >添加分类</AuthorityComponent>
      </div>
      <el-tree
        v-loading="loading"
        :data="treeData"
        :expand-on-click-node="false"
        node-key="id"
        default-expand-all
        draggable
        @node-drop="handleDrop"
      >
        <span slot-scope="{ node, data }" class="custom-tree-node">
          <span style="width: 200px; display: inline-block;">{{ data.nodeName+'(' + data.informationNum+')' }}</span>
          <span class="edit">
            <AuthorityComponent
              ComponentName="el-button"
              v-if="$route.meta.authority.subPage.setting.button.edit"
              type="text"
              size="mini"
              @click="() => edit(node,data)"
            >修改</AuthorityComponent>
            <AuthorityComponent
              ComponentName="el-button"
              v-if="node.level < 2 && $route.meta.authority.subPage.setting.button.create"
              type="text"
              size="mini"
              @click="() => append(node,data)"
            >添加</AuthorityComponent>
            <AuthorityComponent
              ComponentName="el-button"
              v-if="$route.meta.authority.subPage.setting.button.release"
              type="text"
              style="color:red"
              size="mini"
              @click="() => remove(node, data)"
            >删除</AuthorityComponent>
          </span>
        </span>
      </el-tree>
    </el-card>

  </div>
</template>

<script>
// import { findGradeTree, infoTreeSave, infoTreeDragSort, infoTreeUpdate, infoTreeDel } from '@/api/article'
export default {
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      loading: true
    }
  },
  created() {
    this.getGradeTree()
    console.log("资讯管理设置界面权限=>", this.$route.meta.authority);
  },
  methods: {
    getGradeTree() {
      this.loading = true
      const opt = {
        url: '/acb/2.0/informationCategory/tree',
        method: 'get',
        data: {},
        success: res => {
          this.treeData = res.value
          this.loading = false
        } 
      }
      this.$request(opt)
    },
    add() {
      this.$prompt('请输入分类名称', '添加', {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          if (!value) {
            this.$message.warning('请输入分类名称')
            return
          }
          this.loading = true
          // infoTreeSave({
          //   nodeName: value,
          //   parentNodeId: 0
          // }).then(res => {
          //   if (res.data.resultCode === 200) {
          //     // const newChild = { id: res.data.data, label: value, children: [] }
          //     // if (!this.treeData.children) {
          //     //   this.$set(this.treeData, 'children', [])
          //     // }
          //     // this.treeData.children.push(newChild)
          //     this.getGradeTree()
          //   } else {
          //     this.$message.error(res.data.message)
          //     this.loading = false
          //     this.getGradeTree()
          //   }
          // }).catch(err => {
          //   this.$message.error(err)
          //   this.loading = false
          //   this.getGradeTree()
          // })
          const opt = {
            url: '/acb/2.0/informationCategory/add',
            method: 'post',
            config: {
              headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              },
            },
            data: {
              nodeName: value,
              operationUser: sessionStorage.userId
            },
            success: res => {
              this.getGradeTree()
            },
            fail: err => {
              this.loading = false
            }
          }
          this.$request(opt)
        }).catch(() => {
          this.loading = false
        })
    },
    edit(node, data) {
      this.$prompt('请输入分类名称', '修改', {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: '取消',
        inputValue: data.nodeName
      })
        .then(({ value }) => {
          if (!value) {
            this.$message.warning('请输入分类名称')
            return
          }
          this.loading = true
          // infoTreeUpdate({
          //   nodeName: value,
          //   nodeId: data.id
          // }).then(res => {
          //   if (res.data.resultCode === 200) {
          //     this.$set(data, 'label', value)
          //   } else {
          //     this.$message.error(res.data.message)
          //   }
          //   this.loading = false
          // }).catch(err => {
          //   this.$message.error(err)
          //   this.loading = false
          // })
          const opt = {
            url: '/acb/2.0/informationCategory/update',
            method: 'post',
            config: {
              headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              },
            },
            data: {
              categoryId: data.categoryId,
              nodeName: value,
              operationUser: sessionStorage.userId
            },
            success: res => {
              this.$set(data, 'nodeName', value)
              this.loading = false
            },
            fail: err => {
              this.loading = false
            }
          }
          this.$request(opt)
        })
        .catch(() => {
          this.loading = false
        })
    },
    append(node, data) {
      if (node.level < 2) {
        this.$prompt('请输入分类名称', '添加', {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: '取消'
        })
          .then(({ value }) => {
            if (!value) {
              this.$message.warning('请输入分类名称')
              return
            }
            this.loading = true
            // infoTreeSave({
            //   nodeName: value,
            //   parentNodeId: data.id
            // }).then(res => {
            //   if (res.data.resultCode === 200) {
            //     const newChild = { id: res.data.data, label: value, children: [], number: 0 }
            //     if (!data.children) {
            //       this.$set(data, 'children', [])
            //     }
            //     data.children.push(newChild)
            //     this.loading = false
            //   } else {
            //     this.$message.error(res.data.message)
            //     this.loading = false
            //   }
            // }).catch(err => {
            //   this.$message.error(err)
            //   this.loading = false
            // })
            const opt = {
              url: '/acb/2.0/informationCategory/add',
              method: 'post',
              config: {
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8'
                },
              },
              data: {
                parentNodeId: data.categoryId,
                nodeName: value,
                operationUser: sessionStorage.userId
              },
              success: res => {
                this.getGradeTree()
              },
              fail: err => {
                this.loading = false
              }
            }
            this.$request(opt)
          }).catch(() => {
            this.loading = false
          })
      } else {
        this.$message.error('只能增加2级')
      }
    },
    remove(node, data) {
      if (data.informationNum > 0) {
        this.$message.error('当前分类下有关联文章，修改文章为其他分类后，可删除')
        return
      }
      if (data.children) {
        if (data.children.length > 0) {
          this.$message.error('当前分类下有子分类，请先删除子分类')
          return
        }
      }
      this.$confirm(`确定删除名称为 ${data.nodeName} 的类别?`, this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        // infoTreeDel({
        //   nodeId: data.id
        // }).then(res => {
        //   if (res.data.resultCode === 200) {
        //     const parent = node.parent
        //     const children = parent.data.children || parent.data
        //     const index = children.findIndex(d => d.id === data.id)
        //     children.splice(index, 1)
        //   } else {
        //     this.$message.error(res.data.message)
        //   }
        //   this.loading = false
        // }).catch(err => {
        //   this.$message.error(err)
        //   this.loading = false
        // })
        const opt = {
          url: '/acb/2.0/informationCategory/delete',
          method: 'post',
          data: {
            informationCategoryId: data.categoryId
          },
          success: res => {
            const parent = node.parent
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.categoryId === data.categoryId)
            children.splice(index, 1)
            this.loading = false
          },
          fail: err => {
            this.loading = false
          }
        }
        this.$request(opt)
      }).catch(() => {
        this.loading = false
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      if (draggingNode.level === 1 && draggingNode.childNodes.length !== 0 && dropNode.level === 2) {
        this.$message.error('父类带子类时，不可以拖拽至其它父类下做子类')
        this.getGradeTree()
      } else if (draggingNode.level === 1 && draggingNode.childNodes.length !== 0 && dropNode.level === 1 && dropType === 'inner') {
        this.$message.error('父类带子类时，不可以拖拽至其它父类下做子类')
        this.getGradeTree()
      } else if ((dropNode.level === 2 && dropType !== 'inner') || dropNode.level === 1) {
        this.loading = true
        let dType = ''
        if (dropType === 'before') {
          dType = 1
        } else if (dropType === 'after') {
          dType = 2
        } else {
          dType = 3
        }
        const opt = {
          url: '/acb/2.0/informationCategory/dragSort',
          method: 'post',
          config: {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            },
          },
          data: {
            sourceId: draggingNode.data.categoryId,
            targetId: dropNode.data.categoryId,
            dragType: dType
          },
          success: res => {
            this.getGradeTree()
            this.loading = false
          },
          fail: err => {
            this.loading = false
          }
        }
        this.$request(opt)
        // infoTreeDragSort({
        //   'dragPosition': dropType,
        //   'sourceNode': {
        //     'nodeId': draggingNode.data.id,
        //     'nodeName': draggingNode.data.label,
        //     'parentNodeId': draggingNode.data.parentId,
        //     'sortNum': draggingNode.data.sortNum
        //   },
        //   'targetNode': {
        //     'nodeId': dropNode.data.id,
        //     'nodeName': dropNode.data.label,
        //     'parentNodeId': dropNode.data.parentId,
        //     'sortNum': dropNode.data.sortNum
        //   }
        // }).then(res => {
        //   if (res.data.resultCode === 200) {
        //     this.getGradeTree()
        //   } else {
        //     this.$message.error(res.data.message)
        //     this.getGradeTree()
        //   }
        //   this.loading = false
        // }).catch(err => {
        //   this.$message.error(err)
        //   this.loading = false
        // })
      } else {
        this.$message.error('只能增加2级')
        this.getGradeTree()
      }
    }
  }
}
</script>
<style lang='stylus' scoped>
.info {
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-tree {
  width: 500px;
}
.edit {
  margin-left: 100px;
}
.el-tree-node__content {
  border-bottom: solid 1px #ccc;
}
.custom-tree-node
  width 100%
  padding-right 20px
  overflow hidden
</style>
